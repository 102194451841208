import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Seo from "../../Components/Seo";
import ProductUnderNews from "../../Components/ProductUnderNews";
import axios from "axios";
import SeoNews from "../../Components/SeoNews";

const ArticleNew111 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    axios.post("https://api.steam-rent.com/plainlog", { text: "Civilization 7: Дорого,но оправдана ли цена?" });
  }, []);
  return (
    <div onClick={() => navigate("/news")} className="atricle-page">
      <SeoNews
        title="Civilization 7: Дорого,но оправдана ли цена?"
        description={`Одна из самых ожидаемых стратегий 2025 года - Sid Meier's Civilization7,обещает революционизировать жанр глобальных стратегий, но за весьма высокую цену`}
        schemaMarkup={
          {
            articleBody:`Sid Meier's Civilization 7 одна из самых ожидаемых стратегий 2025 года, обещает революционизировать жанр глобальных стратегий, но за весьма высокую цену. Уже сейчас известно, что базовая версия игры будет стоить 70 $ в Steam, а издание Deluxe обойдется в 100 $, в то время как Founders Edition будет стоить 130 $. Это делает её одной из самых дорогих игр в жанре 4X-стратегий, что уже вызывает широкие обсуждения среди игроков.

Ценовая политика издателя объясняется множеством нововведений, которые принесёт новая часть серии. Цивилизация 7 предложит кардинально обновлённый геймплей с акцентом на детализированное строительство империй, более глубокие дипломатические отношения и новейшие технологии управления цивилизациями. По словам разработчиков, игроков ждёт не просто улучшенная графика, но и более интеллектуальный ИИ, который способен принимать решения на уровне реального лидера. В дополнение к этому, игра получит полную локализацию на русском языке, что особенно важно для аудитории СНГ.

Несмотря на высокую цену, поклонники серии уже активно обсуждают будущее нововведение в управлении империями и глобальными процессами. На крупных игровых форумах мнения разделились: одни считают, что игра стоит своих денег, учитывая все технические и игровые улучшения, в то время как другие уверены, что это может отпугнуть часть игроков, особенно тех, кто привык к более доступным ценам на предыдущие части.

        Основные особенности игры:
        Полная локализация: Цивилизация 7 будет доступна на русском языке, что делает игру ещё более привлекательной для русскоязычных игроков.
        Умный ИИ: Один из ключевых аспектов, на который делают ставку разработчики, — это ИИ, способный принимать осознанные решения в условиях глобальной стратегии.
        Новая система управления: Изменения коснулись системы дипломатии, ведения боевых действий и управления ресурсами, что обещает ещё больше глубины в стратегических процессах.
        Cтоимость и доступность rак уже упоминалось, цена игры составляет около 70 $ для пользователей Steam Высокая стоимость объясняется не только техническими улучшениями, но и увеличившимися затратами на разработку и поддержку игры на нескольких платформах. Впрочем, несмотря на высокую цену, преданные поклонники, скорее всего, не упустят возможность опробовать новейшую часть Цивилизации, которая обещает стать одним из самых амбициозных проектов в серии.`,
          datePublished: "2024-09-20T00:00:00Z",
          image:'https://api.steam-rent.com/img/news/News_Civilization_2.webp',
          headline:`Sid Meier's Civilization 7: Дорого,но оправдана ли цена?`,
          description:`Sid Meier's Civilization 7" — это одна из самых ожидаемых стратегий 2025 года, которая обещает революционные изменения в жанре глобальных стратегий. С высокой ценой в 70 $ за базовую версию, игра привлекает внимание благодаря множеству нововведений, включая детализированное строительство империй, улучшенный ИИ и полную локализацию на русском языке. Несмотря на споры среди поклонников о ценовой политике, игра обещает стать одним из самых амбициозных проектов в серии.`

          }
        }
      />    
      <div onClick={(e) => e.stopPropagation()} className="container">
        <h1>
            Цивилизация 7: Дорогая игра, которая может изменить жанр стратегий   
        </h1>

        <img
          src={"https://api.steam-rent.com/img/news/News_Civilization_2.webp"} alt="Civilization 7 цена"
        />
        <p><span>Sid Meier's Civilization 7</span> одна из самых ожидаемых стратегий 2025 года, обещает революционизировать жанр глобальных стратегий, но за весьма высокую цену. Уже сейчас известно, что базовая версия игры будет стоить <span> 70$</span> в Steam, а издание Deluxe обойдется в <span>100$</span>, в то время как Founders Edition будет стоить <span> 130$</span>. Это делает её одной из самых дорогих игр в жанре 4X-стратегий, что уже вызывает широкие обсуждения среди игроков.

        </p>

        <p>
          Ценовая политика издателя объясняется множеством нововведений, которые принесёт новая часть серии. Цивилизация 7 предложит кардинально обновлённый геймплей с акцентом на детализированное строительство империй, более глубокие дипломатические отношения и новейшие технологии управления цивилизациями. По словам разработчиков, игроков ждёт не просто улучшенная графика, но и более интеллектуальный ИИ, который способен принимать решения на уровне реального лидера. В дополнение к этому, игра получит полную локализацию на русском языке, что особенно важно для аудитории СНГ.        </p>

        <p>Несмотря на высокую цену, поклонники серии уже активно обсуждают будущее нововведение в управлении империями и глобальными процессами. На крупных игровых форумах мнения разделились: одни считают, что игра стоит своих денег, учитывая все технические и игровые улучшения, в то время как другие уверены, что это может отпугнуть часть игроков, особенно тех, кто привык к более доступным ценам на предыдущие части.
        </p>


        <h2>Основные особенности игры:  </h2>

        <ul className="news-list">
          <li>Полная локализация: Цивилизация 7 будет доступна на русском языке, что делает игру ещё более привлекательной для русскоязычных игроков.          </li>
          <li>Умный ИИ: Один из ключевых аспектов, на который делают ставку разработчики, — это ИИ, способный принимать осознанные решения в условиях глобальной стратегии.</li>
          <li>Новая система управления: Изменения коснулись системы дипломатии, ведения боевых действий и управления ресурсами, что обещает ещё больше глубины в стратегических процессах.  </li>
        </ul>

        <p>
Cтоимость и доступность rак уже упоминалось, цена игры составляет около 70 $  для пользователей Steam
Высокая стоимость объясняется не только техническими улучшениями, но и увеличившимися затратами на разработку и поддержку игры на нескольких платформах. Впрочем, несмотря на высокую цену, преданные поклонники, скорее всего, не упустят возможность опробовать новейшую часть Цивилизации, которая обещает стать одним из самых амбициозных проектов в серии.
        </p>



        
        
         <h3>
          Взять аккаунт в аренду или купить его вы сможете  уже с 5 февраля 2025 года )
        </h3>



        {/* <ProductUnderNews
          id={1724139863134}
          imgLink="https://image.api.playstation.com/vulcan/ap/rnd/202405/2122/ac63f546eed35848f4a155a9fa9ee64f0bcd7b2a175dbd78.png"
          title={"Black Myth: Wukong Deluxe"}
        />  */}
      </div>
    </div>
  );
};

export default ArticleNew111;
