import { useEffect, useState } from "react";
import Seo from "../../Components/Seo";
import Product from "../../store/Product";
import "./p2p.scss";
import P2pCopy from "./p2pCopy";
import P2PLoader from "./P2PLoader/P2PLoader";
import P2PTip from "./p2pTip";
import Timer from "./Timer";

const P2PPage = () => {
  const [visibleTip, setVisibleTip] = useState<number>(1);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isTimeOut, setIsTimeOut] = useState<boolean>(false);
  const { amountWithouthCashback } = Product;

  if (isTimeOut) {
    return (
      <div className="p2p-page">
        <div className="payment-details__container_processing">
          <img src={require("./../../assets/img/cancelled.png")} alt="" />
          <h3>Оплата не прошла</h3>
          <p>
            Если вы осуществили перевод и видите эту страницу, напишите об этом
            в чат или по контактам
          </p>
        </div>
        ;
      </div>
    );
  }

  return (
    <div className="p2p-page">
      <Seo title="Оплата P2P" description="" />
      {!isPaid ? (
        <div className="payment-details__container">
          <div className="payment-details__header"></div>
          <h2>Оплата c карты на карту</h2>
          <div className="payment-details__main">
            {/* -------- */}
            <div
              className={`payment-details__main__wrapper ${
                visibleTip === 1 && "payment-details__main__wrapper_highlighted"
              }`}
            >
              <P2PTip
                text={
                  "Скопируйте номер карты и введите его в раздел «Перевод на карту» в вашем личном кабинете или приложении вашего банка."
                }
                num={1}
                currNumb={visibleTip}
                setVisibleTip={setVisibleTip}
              />
              <div className="payment-details__main__card">
                <div className="payment-details__main__card-title">
                  Номер карты
                </div>
                <div className="payment-details__main__card-inf">
                  4441 1144 5200 2239
                </div>
              </div>

              <P2pCopy text={String("4441114452002239")} />
            </div>

            <div
              className={`payment-details__main__wrapper ${
                visibleTip === 2 && "payment-details__main__wrapper_highlighted"
              }`}
            >
              <P2PTip
                text={
                  "Скопируйте сумму и введите ее в раздел «Перевод на карту» в вашем личном кабинете или приложении вашего банка."
                }
                num={2}
                currNumb={visibleTip}
                setVisibleTip={setVisibleTip}
              />
              <div className="payment-details__main__card">
                <div className="payment-details__main__card-title">Сумма </div>
                <div className="payment-details__main__card-inf">
                  {amountWithouthCashback} UAH
                </div>
              </div>
              <P2pCopy text={String(amountWithouthCashback)} />
            </div>
            <div className="payment-details__main__wrapper">
              <div className="payment-details__main__card">
                <div className="payment-details__main__card-title">
                  Комментарий
                </div>
                <div className="payment-details__main__card-inf">
                  Ничего не пишите в комментарии
                </div>
              </div>
            </div>
          </div>
          <div className="payment-details__timer__wrapper">
            <div className="payment-details__timer">
              <Timer timeIsOver={setIsTimeOut} min={15} />
            </div>
            <div
              onClick={() => setVisibleTip(1)}
              className="payment-details__timer__instr"
            >
              Инструкция
            </div>
          </div>

          <div className="payment-details__pay__wrapper">
            <P2PTip
              text={
                "После осуществления перевода, нажмите эту кнопку, чтобы ваш платеж пошел в обработку"
              }
              num={3}
              currNumb={visibleTip}
              setVisibleTip={setVisibleTip}
            />
            <div
              className="payment-details__pay-btn"
              onClick={() => setIsPaid(true)}
            >
              Я оплатил
            </div>
          </div>
        </div>
      ) : (
        <div className="payment-details__container_processing">
          <P2PLoader />
          <h3> Обработка платежа....</h3>
          <p>Обычно это происходиит в течении 15мин</p>
          <p>В рабочее время</p>
        </div>
      )}
    </div>
  );
};

export default P2PPage;
