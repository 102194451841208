import { FC } from "react";
import { Helmet } from "react-helmet-async";
import {  InewsArticle, INewsArticleSchemaMarkup } from "../types/types";

interface SeoNewsProps {
  title: string;
  description: string;
  canonicalUrl?: string;
  schemaMarkup: {
    headline:string;
    image:string;
    datePublished: string;
    description: string;
    articleBody: string;
  };
}


const SeoNews: FC<SeoNewsProps> = ({ title, description, canonicalUrl,schemaMarkup }) => {

  const schemaData:INewsArticleSchemaMarkup = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: schemaMarkup.headline,
    image: schemaMarkup.image,
    datePublished: schemaMarkup.datePublished,
    dateModified: schemaMarkup.datePublished,
    author: {
      "@type": "Person",
      name: "Alex Wax"
    },
    description: schemaMarkup.description,
    articleBody: schemaMarkup.articleBody,
  };
  

  console.log({schemaData});
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {schemaMarkup && (
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      )}
    </Helmet>
  );
};

export default SeoNews;
